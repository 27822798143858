<fal *ngIf="showMdIcon" [i]="icon" class="text-primary ml-3 mr-1" [ngbTooltip]="tooltip"></fal>
<ng-select [items]="options"
           [ngModel]="physician"
           [bindValue]="bindValue"
           bindLabel="name"
           [multiple]="multi"
           [disabled]="disabled"
           class="{{defaultBodySelectStyle ? inputClasses : ''}}"
           (ngModelChange)="onPhysicianChange($event)"
           [labelForId]="placeholder"
           [inputAttrs]="{'title': placeholder}"
           [placeholder]="placeholder">
    <ng-template ng-option-tmp let-physicianOpt="item" let-clear="clear">
        <div class="d-flex align-items-center" [ngbTooltip]="showEmailText ? '' : physicianOpt?.email" [placement]="['right', 'left', 'top']">
            <app-user-icon [user]="physicianOpt" size="sm" [hasTooltip]="false" classList="mr-2"></app-user-icon>
            <span class="mr-2 o-hidden">{{physicianOpt|humanName}}</span>
            <small *ngIf="showEmailText" class="text-muted ml-auto">{{physicianOpt.email}}</small>
        </div>
    </ng-template>
    <ng-template ng-label-tmp let-physicianOpt="item" let-clear="clear">
        <div class="d-flex align-items-center" [ngbTooltip]="showEmailText ? '' : physicianOpt?.email" [placement]="['right', 'left', 'top']">
            <app-user-icon [user]="physicianOpt" size="sm" [hasTooltip]="false" classList="mr-2"></app-user-icon>
            <span class="mr-2 o-hidden">{{physicianOpt|humanName}}</span>
            <small *ngIf="showEmailText" class="text-muted ml-auto mr-2">{{physicianOpt.email}}</small>
        </div>
        <span class="ng-value-icon right" (click)="clear(physicianOpt)">×</span>
    </ng-template>
</ng-select>
