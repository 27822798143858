import {Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {APIService, PhysicianSerializer} from '../../../../@core/api.service';
import {PhysicianTeam, PhysicianTeamUser} from '../../../../models/physician-team';
import {takeUntil} from 'rxjs/operators';
import {User} from '../../../../models/user';
import {UnsubscribeComponent} from '../../../../@core/fc-component';

type PhysicianSelectOutput =
    PhysicianSerializer | PhysicianSerializer[]
    | PhysicianTeamUser | PhysicianTeamUser[]
    | PhysicianTeam | PhysicianTeam[]
    | number | number[];

@Component({
    selector: 'app-physician-select',
    templateUrl: './physician-select.component.html',
    styleUrls: ['./physician-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PhysicianSelectComponent extends UnsubscribeComponent implements OnInit {
    @Input() physician: number | number[];
    @Input() options: (PhysicianSerializer | PhysicianTeamUser | PhysicianTeam)[];
    @Input() showEmailText = false;
    @Input() showMdIcon = true;
    @Input() icon = 'user-md';
    @Input() bindValue = 'id';
    @Input() @HostBinding('class.d-flex') @HostBinding('class.align-items-center') defaultBodySelectStyle = true;
    @Input() inputClasses = 'body-select wide-input flex-grow-1';
    @Input() multi = false;
    @Input() disabled = false;
    @Input() fetchOptions = false;
    @Input() placeholder = 'All Physicians';
    @Input() tooltip: string;

    @Output() physicianChange = new EventEmitter<PhysicianSelectOutput>();

    ngOnInit() {
        this.fetchOptionsIfNeeded();
    }

    onPhysicianChange(event: PhysicianSerializer | PhysicianTeamUser | PhysicianTeam) {
        this.physicianChange.emit(event || null);
    }

    fetchOptionsIfNeeded() {
        if (!this.fetchOptions) return;
        this.cancelRequest$.next();
        APIService.PhysicianViewSet.list().pipe(takeUntil(this.cancelRequest$)).subscribe(physicians => {
            this.options = physicians.map(x => new User(x)).sort((a, b) => b.active_patients - a.active_patients);
        });
    }
}
